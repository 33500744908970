import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useCallback, useEffect } from "react";

import { useGetSubcourseQueries } from "../queries/useGetSubcourseQueries";

const Course = ({ setLink, params, setParams }) => {
  console.log(params);
  const { data: subcourseList } = useGetSubcourseQueries(params);

  // const fetch = useCallback(() => {
  //   console.log(22333333333, params);
  //   GetSubCourse(params);
  // }, [params]);

  // useEffect(() => {
  //   fetch();
  // }, [fetch]);

  const handleClick = (action, courseDetailId) => {
    params.courseDetailId = courseDetailId;
    setParams(params);
    if (action === "view") {
      setLink("course/module/view");
    } else {
      setLink("edit/course/module/view");
    }
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return (
    <>
      {typeof subcourseList === "object" && subcourseList.length > 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell align="left">Title</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subcourseList.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.status}
                  </TableCell>
                  <TableCell align="left">{row.title}</TableCell>
                  <TableCell align="center">
                    <Stack direction="row" spacing={2}>
                      <Button
                        id="basic-button"
                        onClick={() => handleClick("view", row.id)}
                      >
                        View
                      </Button>
                      <Button
                        id="basic-button"
                        onClick={() => handleClick("edit", row.id)}
                      >
                        Edit
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Course;
