import { useGetbackendMenuQueries } from "../queries/useGetbackendMenuQueries";
import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

const Navbar = ({link, setLink, setChildParams}) => {
  const { data: backendMenuList } = useGetbackendMenuQueries();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };
  const handleClose = (itemLink, params) => {
    setAnchorEl(null);
    setLink(itemLink);
    setChildParams(params);
  };
  return (
    <Stack direction="row" spacing={2}>
      {typeof backendMenuList !== "undefined" && backendMenuList !== null ? (
        backendMenuList.map((backendMenuItem, index) => {
          return (
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={(e) => handleClick(index, e)}
              >
                {backendMenuItem.title}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl && anchorEl[index]}
                open={ Boolean(anchorEl && anchorEl[index])}
                onClose={() => handleClose(backendMenuItem.link)}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {backendMenuItem.childList
                  ? backendMenuItem.childList.map(
                      (backendMenuChilItem, index1) => (
                        <MenuItem onClick={() => handleClose(backendMenuChilItem.link, backendMenuChilItem.params)}> {backendMenuChilItem.title}</MenuItem>
                      )
                    )
                  : ""}
              </Menu>
            </div>
          );
        })
      ) : (
        <div></div>
      )}
    </Stack>
  );
};
export default Navbar;
