import { useGetCourseDetailQueries } from "../queries/useGetCourseDetailQueries";

const CourseDetail = ({ params }) => {
  const { data: subcourseList } = useGetCourseDetailQueries(params);
  return (
    <>
      {typeof subcourseList === "object" && subcourseList.length > 0 ? (
        <div>
          {subcourseList.map((row) => (
            <div>
              <h2 variant="h4" align="center">
                {row.title}
              </h2>
              <p> {row.summary}</p>
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default CourseDetail;
