import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import useAdminAuth from "../hooks/useAdminAuth";

const LoginPage = () => {
  const { setUserObject } = useAdminAuth();
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { mutateAsync: useLogin } = useMutation({
    mutationKey: [""],
    mutationFn: async (params) => {
      const res = await axios.post("http://localhost/api/login.php", params);
      return res;
    },
  });
  useEffect(() => {
    setErrorMsg("");
  }, [password, username]);
  const navigate = useNavigate();
  const OnFormSubmit = async (e) => {
    e.preventDefault();
    const {
      data: data,
      message: message,
      status: status,
    } = await useLogin({ username: username, password: password });
    if (status === "error") {
      setErrorMsg(message);
    } else {
      setUserObject(data);
      localStorage.setItem("loggedInUserInfoObject", JSON.stringify(data));
      navigate(
        {
          pathname: "/admin",
        },
        {
          replace: true,
        }
      );
    }
  };
  return (
    <>
      <Box
        component="form"
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100vh"
        onSubmit={OnFormSubmit}
      >
        <Stack spacing={3}>
          <Typography variant="h4" align="center">
            LOGIN
          </Typography>
          <TextField
            name="userMo[userName]"
            id="id_userMo_userName"
            label="Username"
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            name="userMo[password]"
            id="id_userMo_password"
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
          />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Remember Me"
              name="remember_me"
            />
          </FormGroup>

          <Button type="submit" variant="contained">
            Login
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default LoginPage;
