import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import useAdminAuth from "../hooks/useAdminAuth";
const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const {  userObject, setUserObject } = useAdminAuth();

    const loggedInUserInfoObject = JSON.parse(localStorage.getItem('loggedInUserInfoObject'));
   

    const verifyLoggedInUserInfoObject = () => {
        setUserObject(loggedInUserInfoObject);
        setIsLoading(false);
    }
    useEffect(()=>{
        !userObject.user? verifyLoggedInUserInfoObject() : setIsLoading(false);
    },[]);
    return <>
    {isLoading ? <p>loading...</p>: <Outlet/>}
    </>
}

export default PersistLogin;