import axios from "axios";
import { useQuery } from "react-query";
import useAdminAuth from "../hooks/useAdminAuth";

const fetchMenu1 = async ({ queryKey }) => {
  const [params] = queryKey;
  const { data } = await axios.get(
    `http://localhost/api/get-course-detail-view.php`,
    { params: params }
  );
  return data;
};

export const useGetCourseDetailQueries = (params) => {
  const { userObject } = useAdminAuth();
  const userId = userObject?.user.id;
  params.userId = userId;
  return useQuery([params], fetchMenu1);
};
