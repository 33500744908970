import { Option, Select, Textarea } from "@mui/joy";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import useAdminAuth from "../hooks/useAdminAuth";
import { useGetCourseDetailForEditQueries } from "../queries/useGetCourseDetailForEditQueries";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";

const CourseEdit = ({ params, setParams }) => {
  const { data: subcourseList } = useGetCourseDetailForEditQueries(params);
  console.log(111188888, subcourseList);
  console.log(6666666, subcourseList[0].id);

  const { setUserObject } = useAdminAuth();
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [statusCourse, setStatus] = useState("");
  const [isMark, setIsMark] = useState("");
  const [isPublic, setIsPublic] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [okMsg, setOkMsg] = useState("");
  const { mutateAsync: useEdit } = useMutation({
    mutationKey: [""],
    mutationFn: async (params) => {
      const res = await axios.post(
        "http://localhost/api/edit-course-detail.php",
        params
      );
      return res;
    },
  });
  useEffect(() => {
    setErrorMsg("");
  }, [title, summary]);
  const navigate = useNavigate();
  const OnFormSubmit = async (e) => {
    e.preventDefault();
    const {
      data: data,
      message: message,
      isSuccess: isSuccess,
      status: status,
    } = await useEdit({
      courseDetailId: subcourseList[0].id,
      title: title,
      summary: summary,
      isMark: isMark,
      isPublic: isPublic,
    });
    console.log("STATUS", status);
    if (status === "error") {
      setErrorMsg(message);
    } else {
      setOkMsg("Record updated successfully!")
    }
  };
  const handleChange = (event) => {
    this.setIsPublic({ checked: event.target.checked });
  };
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      minHeight="100vh"
      onSubmit={OnFormSubmit}
    >
      {typeof subcourseList === "object" && subcourseList.length === 1 ? (
        <>
          {subcourseList.map((row) => (
            <Stack spacing={3}>
              <Typography variant="h4" align="left">
                Edit course
              </Typography>
              {errorMsg ? (
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="error">
                  {errorMsg}
                </Alert>
              ) : (
                <div></div>
              )}
              {okMsg ? (
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                  {okMsg}
                </Alert>
              ) : (
                <div></div>
              )}

              <label>Title</label>
              <TextField
                name="userMo[userName]"
                id="id_userMo_userName"
                defaultValue={row.title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  console.log(6567, e.target.value);
                }}
              />
              <label>Summary</label>
              <Textarea
                minRows={2}
                defaultValue={row.summary}
                onChange={(e) => setSummary(e.target.value)}
              />
              <label>Status</label>
              <Select
                defaultValue={row.status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <Option value="DONE">DONE</Option>
                <Option value="TODO">TODO</Option>
                <Option value="WORKING">WORKING</Option>
              </Select>
              <label>Mark</label>
              <Select
                defaultValue={row.is_mark}
                onChange={(e) => setIsMark(e.target.value)}
              >
                <Option value="0">None</Option>
                <Option value="1">Marked</Option>
              </Select>
              <label>Public</label>
              <Checkbox label="Public" />
              <Button type="button" onClick={OnFormSubmit} variant="contained">
                Save
              </Button>
            </Stack>
          ))}
        </>
      ) : (
        <div></div>
      )}
    </Box>
  );
};

export default CourseEdit;
