import * as React from "react";
import Navbar from "./Navbar";

import MainContent from "../config/MainContent";

const MainLayout = () => {
  const [link, setLink] = React.useState(null);
  const [params, setParams] = React.useState(null);
  return (
    <>
      <Navbar link={link} setLink={setLink} setChildParams={setParams} />
      <div id="main">
        <MainContent link={link} params={params} setLink={setLink} setParams={setParams} />
      </div>
    </>
  );
};
export default MainLayout;
