import { useQuery } from 'react-query';
import QueryKeys from './queryKeys';
import axios from "axios";
import useAdminAuth from '../hooks/useAdminAuth';
const fetchMenu = async (params = null) => {
    const { data } = await axios.get(`http://localhost/api/getmenu.php`, { params: params });
    return data.data;
};

export const useGetbackendMenuQueries = () => {
    const { userObject } = useAdminAuth();
    const userName = userObject?.user.userName;
    const key = QueryKeys.GET_ALL_MENU + '-' + userName;
    return useQuery({
        queryKey: [key],
        queryFn: fetchMenu,
    });
};
