import axios from "axios";
import { useQuery } from "react-query";
import useAdminAuth from "../hooks/useAdminAuth";

const fetchMenu = async ({ queryKey }) => {
  const [params] = queryKey;
  const { data } = await axios.get(
    `http://localhost/api/get-subcourse-view.php`,
    { params: params }
  );
  return data;
};

export const useGetSubcourseQueries = (params) => {
  const { userObject } = useAdminAuth();
  const userId = userObject?.user.id;
  params.userId = userId;
  return useQuery([params], fetchMenu);
};
