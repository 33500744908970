import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route
} from "react-router-dom";
import PersistLogin from "../components/PersistLogin";
import RequireAdminAuth from "../components/RequireAdminAuth";
import MainLayout from "../layouts/MainLayout";
import LoginPage from "../pages/LoginPage";

export const BrowserRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="" element={<Navigate to="/login" replace />} />
      <Route path="/login" element={<LoginPage />} />
      <Route element={<PersistLogin />}>
        <Route element={<RequireAdminAuth />}>
          <Route path="/admin" element={<MainLayout />}></Route>
        </Route>
      </Route>
    </Route>
  ),
);
