import HomePage from "../pages/HomePage";
import Course from "../pages/Course";
import CourseDetail from "../pages/CourseDetail";
import CourseEdit from "../pages/CourseEdit";
import NotFoundPage from "../pages/NotFoundPage";

const MainContent = ({ link, setLink, params, setParams }) => {
    console.log(link);
    console.log(params);
  const componentMapping = {
    "": { componentName: "HomePage", component: <HomePage /> },
    "subcourse/view": {
      componentName: "Course",
      component: <Course params={params} setLink={setLink} setParams={setParams} />,
    },
    "admin": { componentName: "HomePage", component: <HomePage /> },
    "course/module/view": {
      componentName: "View Detail",
      component: <CourseDetail params={params} />,
    },
    "edit/course/module/view": {
        componentName: "Edit View",
        component: <CourseEdit params={params}  setParams={setParams}/>,
      },
  };

  return <>{componentMapping[link]?.component ?? <NotFoundPage />}</>;
};
export default MainContent;
