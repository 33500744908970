import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { AdminAuthProvider } from './context/AdminAuthProvider';
import { BrowserRouter } from './routers/routes';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
        <AdminAuthProvider>
            <RouterProvider router={BrowserRouter} />
        </AdminAuthProvider>
    </QueryClientProvider>
  );
}

export default App;
