import axios from "axios";
import { useQuery } from "react-query";
import useAdminAuth from "../hooks/useAdminAuth";

const fetchMenu = async ({ queryKey }) => {
  const [params] = queryKey;
  console.log(6767,params);
  const { data } = await axios.get(
    `http://localhost/api/get-course-detail-edit-view.php`,
    { params: params }
  );
  console.log(88877,data);
  return data;
};

export const useGetCourseDetailForEditQueries = (params) => {
  const { userObject } = useAdminAuth();
  const userId = userObject?.user.id;
  params.userId = userId;
  return useQuery([params], fetchMenu);
};
